import React, { useEffect, useState } from 'react';
import StockDetail from './StockDetail.component';
import { Box } from '@material-ui/core';
import { sortByAscendingPriceOrColor } from '../../../utils/sort.utils';
import { STOCK_STATES } from '../../../utils/constants';

const StockList = ({
  stockList,
  onReservationClick,
  isCatalogCarmuv = false,
  currency_symbol,
  setSelectedStock,
  selectedStock,
  isLoggedIn
}) => {
  const [priceSortedStockList, setPriceSortedStockList] = useState([]);

  useEffect(() => {
    const stockActives = stockList.filter(s=> s.estado !== STOCK_STATES.RESERVED).sort(sortByAscendingPriceOrColor);
    const stockReserved = stockList.filter(s=> s.estado === STOCK_STATES.RESERVED);
    const stocks = [...stockActives, ...stockReserved];
    setPriceSortedStockList(stocks);
  }, []);

  useEffect(() => {
    setSelectedStock(priceSortedStockList[0]?.id);
  }, [priceSortedStockList]);

  return (
    <Box marginTop="1.5rem" display="flex" flexDirection="column" alignItems="center">
      {stockList.length > 0 &&(
          <Box width="100%">
            {priceSortedStockList
              .map((item, index) => {
                return <StockDetail
                  key={item.id}
                  index={index}
                  selected={selectedStock === item.id}
                  price={item.price}
                  color={item.color}
                  observation={item.observation}
                  discount={item.discount}
                  stockId={item.id}
                  onSelect={setSelectedStock}
                  onReservationClick={onReservationClick}
                  isCatalogCarmuv={isCatalogCarmuv}
                  currency_symbol={currency_symbol}
                  isLoggedIn={isLoggedIn}
                  isReserved={item['estados'].every(s=> s === STOCK_STATES.RESERVED)}
                />;
              })}
          </Box>
        )}
    </Box>
  );
};

export default StockList;
