import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '../../common/radio.component';
import Text from '../../common/text.component';
import ColorBox from '../../common/ColorBox.component';
import Button from '../../common/button.component';
import { formatPrice } from '../../../utils/currency.utils';
import { baseTheme, PALETTE } from '../../../assets/theme';

const BOOKING_TEXT = 'Solicitar más información';
const RESERVED_TEXT = 'Reservado';
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F7F7F7',
    borderRadius: '10px',
    width: '100%',
    padding: '15px 18px',
  },
  radioButtonContainer: {
    height: '32px',
    width: '33px',
    marginRight: '5px'
  },
  radioButton: {
    margin: '0 -5px 0 4px'
  },
  price: {
    fontSize: theme.typography.size.large,
    marginRight: theme.spacing(2),
  },
  button: {
    margin: '1rem 0',
  },
  discount: {
    fontWeight: 'bold'
  },
  observation: {
    borderRadius: '5px',
    backgroundColor: '#E8E8E8',
    height: 'min-content',
    padding: '0 0.5rem',
    lineHeight: '1.5rem',
    fontSize: '12px',
    fontWeight: '400',
    color: PALETTE.primary.main2,
  },
  colorName: {
    marginTop: '7px',
    marginRight: theme.spacing(2),
  },
  reserved: {
    background: PALETTE.grayScale.g300,
    margin: '10px -18px -15px -18px',
    borderRadius: '0px 0px 9px 9px',
    textAlign: 'center',
    padding: '5px',
    fontWeight: baseTheme.typography.weight.semibolder,
    color: PALETTE.grayScale.g600,
    fontSize: baseTheme.typography.size.little,
  }
}));

const StockDetail = ({
  selected,
  onSelect = () => {
  },
  stockId,
  price,
  color,
  observation,
  discount,
  onReservationClick = () => {
  },
  isCatalogCarmuv,
  currency_symbol,
  isLoggedIn,
  isReserved
}) => {
  const classes = useStyles();
  const display = selected ? 'flex' : 'none';
  const positiveDiscount = Math.abs(discount);

  function handleReservation(evt) {
    evt.preventDefault();
    onReservationClick();
  }

  function formatObservation(obs) {
    let formattedObservation = obs.toLowerCase();
    formattedObservation = formattedObservation[0].toUpperCase().concat(formattedObservation.substring(1, obs.length));
    return formattedObservation;
  }

  return (
    <Box id="stock-detail-container" marginBottom="0.625rem" className={classes.container}>
      {isLoggedIn ?
        <>
          <Box display="flex" id="stock-price-color">
            <Box display="flex" alignItems="center" >
              <Box className={classes.radioButtonContainer}>
                {
                  !isReserved &&
                  <Radio
                    checked={selected}
                    onChange={() => onSelect(stockId)}
                    size="small"
                    data-test-id="select-radio"
                    className={classes.radioButton}/>
                }
              </Box>
              <Box display="flex" flexWrap="wrap">
                <Text bold g500 className={classes.price}>{formatPrice(price, currency_symbol)}</Text>
                {observation && <Box className={classes.observation}>{formatObservation(observation)}</Box>}
              </Box>
            </Box>
          </Box>
          <Box id="stock-leyend" marginTop="0.5rem" marginLeft="42px">
            <Text light smaller><span
              className={classes.discount}>{formatPrice(positiveDiscount, currency_symbol)}</span> de descuento sobre el
              precio de venta</Text>
          </Box>
          <Box display="flex" marginLeft="42px" marginTop="4px">
            {
              color
                ? <ColorBox color={color.name} hex={color.color} isCatalogCarmuv={isCatalogCarmuv}/>
                : 'Color no disponible'
            }
          </Box>
          {
            !isReserved &&
            <Box id="stock-leyend-button" display={display} flexDirection="column" alignItems="center">
              <Button
                primary
                data-test-id="open-modal-button"
                className={classes.button}
                fullWidth
                onClick={handleReservation}
              >
                {BOOKING_TEXT}
              </Button>
            </Box>
          }
        </>
        : <>
          <Box display="flex" alignItems={'flex-start'} id="stock-price-color">
            <Box display="flex" alignItems="center" className={classes.radioButtonContainer}>
              {
                !isReserved &&
                <Radio
                  checked={selected}
                  onChange={() => onSelect(stockId)}
                  size="small"
                  data-test-id="select-radio"
                  className={classes.radioButton}/>
              }
            </Box>
            <Box display={'flex'} flexWrap="wrap">
              {
                color
                  ? <ColorBox className={classes.colorName} color={color.name} hex={color.color}
                    isCatalogCarmuv={isCatalogCarmuv}/>
                  : 'Color no disponible'
              }
              {observation &&
                <Box className={classes.observation} marginTop="5px">{formatObservation(observation)}</Box>}
            </Box>
          </Box>
          {
            !isReserved && <Box id="stock-leyend-button" display={display} flexDirection="column" alignItems="center">
              <Button
                primary
                data-test-id="open-modal-button"
                className={classes.button}
                fullWidth
                onClick={handleReservation}
              >
                {BOOKING_TEXT}
              </Button>
            </Box>
          }
        </>
      }
      {
        isReserved &&
        <Box className={classes.reserved}>
          <span>{RESERVED_TEXT}</span>
        </Box>
      }
    </Box>
  );
};

StockDetail.propTypes = {
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  color: PropTypes.object.isRequired,
  discount: PropTypes.node.isRequired,
  onReservationClick: PropTypes.func.isRequired,
};

export default StockDetail;
