import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Text from '../../common/text.component';
import PropTypes from 'prop-types';
import Button from '../../common/button.component';
import ColorBox from '../../common/ColorBox.component';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { baseTheme, PALETTE } from '../../../assets/theme';

const BOOKING_TEXT = 'Solicitar más información';
const RESERVED_TEXT = 'Reservado';

const useStyle = makeStyles(
  (theme) => ({
    borderBottom: {
      borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    },
    button: {
      margin: '1rem 0',
      width: '100%',
      fontSize: theme.typography.size.small,
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600
      }
    },
    greyBox: {
      backgroundColor: '#F6F6F6',
      borderRadius: '10px',
      padding: '1rem',
      width: '100%',
      margin: '0.5rem 1rem'
    },
    locationText: {
      marginLeft: '0.5rem'
    },
    smallTypography: {
      fontSize: theme.typography.size.small
    },
    reserved: {
      background: PALETTE.grayScale.g300,
      margin: '15px 0 20px 0',
      borderRadius: '10px',
      textAlign: 'center',
      padding: '15px',
      width: '100%',
      fontWeight: baseTheme.typography.weight.semibolder,
      color: PALETTE.grayScale.g600,
      fontSize: baseTheme.typography.size.little,
    }
  })
);

const LicenciadoUsado = ({ branchOffice, onReservationClick, stockList, isLoggedIn, isReserved }) => {
  const { name: branchOfficeName } = branchOffice || {};
  const classes = useStyle();
  const { color: { name, color } } = stockList.length && stockList[0].color ? stockList[0] : { color: '', name: '' };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" className={classes.greyBox}>
        <ColorBox color={name} hex={color} isCatalogCarmuv/>
      </Box>
      {isLoggedIn &&
        <>
          <Box display="flex" className={classes.greyBox}>
            <LocationOnIcon/>
            <Text medium className={classes.locationText}>{branchOfficeName}</Text>
          </Box>
        </>
      }
      {
        isReserved
          ? <Box className={classes.reserved}>
            <span>{RESERVED_TEXT}</span>
          </Box>
          : <>
            <Box
              id="stock-leyend-button"
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginBottom="0.625rem"
              width="100%"
            >
              <Button
                primary
                larger
                data-test-id="open-modal-button"
                className={classes.button}
                fullWidth
                onClick={onReservationClick}
              >
                {BOOKING_TEXT}
              </Button>
            </Box>
          </>
      }
    </Box>
  );
};

LicenciadoUsado.prototype = {
  onReservationClick: PropTypes.func
};

LicenciadoUsado.defaultProps = {
  onReservationClick: () => {
  }
};

export default LicenciadoUsado;
